@font-face {
  font-family: 'Montserrat';
  src: url('./assets/fonts/Montserrat.ttf') format('truetype');
}

.general-btn {
  font-family: Montserrat;
    font-size: 1rem;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 0.6rem;
    border: none;
    background-color: #27ad61;
    color: #fff;
    margin-left: 20px;
    max-width: 300px;
    cursor: pointer;
}