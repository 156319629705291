.scrollable {
  overflow: hidden; /* Скрыть стандартный скроллбар */
  position: relative; /* Для позиционирования кастомного скроллбара */
}

.scrollable > div {
  overflow-x: auto; /* Включить горизонтальный скроллинг */
  white-space: nowrap; /* Запретить перенос строк */
  height: 100%; /* Установите высоту по вашему усмотрению */
}

.scrollable > div::-webkit-scrollbar {
  height: 12px; /* Высота скроллбара */
}

.scrollable > div::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Цвет фона трека */
  border-radius: 6px; /* Закругление углов трека */
}

.scrollable > div::-webkit-scrollbar-thumb {
  background: linear-gradient(90.72deg, #7927FF 0%, #8B1EA6 100%); /* Цвет ползунка */
  border-radius: 6px; /* Закругление углов ползунка */
}

.scrollable > div::-webkit-scrollbar-thumb:hover {
  background: #555; /* Цвет ползунка при наведении */
}
